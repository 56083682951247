import React from 'react'

import Layout from '../../components/Layout'


export default class OurDifferencePage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/grass.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #008551, -0.5rem 0 0 #008551',
              backgroundColor: '#008551',
              color: 'white',
              padding: '1rem',
            }}
          >
            Our Difference
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
            <h2>Why Spider Guy? Our highly trained specialists focus on your satisfaction and your safety.</h2>

            <h3>Our specialists are among the most experienced and customer service-oriented land and tree care specialists in the green industry.</h3>

              <h4>Spider Guy’S SERVICE SPECIALISTS…</h4>

                <ul>
                <li>Average 10+ years’ experience in the green industry</li>  
                <li>Are state certified and registered with the Department of Agriculture</li>  
                <li>Graduated from the Spider Guy Service Training and Agronomic Program</li> 
                <li>Graduated from the Spider Guy Horticulture Training Program</li>  
                <li>Completed Spider Guy’s comprehensive Hazardous Communication Program</li> 
                <li>Extensively trained in Targeted and Integrated Pest Management (IPM)</li> 
                <li>When the Spider Guy truck arrives at your home, you can take comfort knowing the care your landscaping receives is better for your family, pets, and the planet.</li> 
                </ul>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}